import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'logo_vk_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="logo_vk_outline_28"><g fill="currentColor"><path d="M17.59 3c2.183 0 3.233.203 4.324.786a5.543 5.543 0 012.3 2.3c.583 1.091.786 2.14.786 4.324v7.18c0 2.183-.203 3.233-.786 4.324a5.543 5.543 0 01-2.3 2.3c-1.091.583-2.14.786-4.324.786h-7.18c-2.183 0-3.233-.203-4.324-.786a5.543 5.543 0 01-2.3-2.3C3.203 20.823 3 19.774 3 17.59v-7.18c0-2.183.203-3.233.786-4.324a5.543 5.543 0 012.3-2.3C7.177 3.203 8.226 3 10.41 3zm0 2h-7.18c-1.881 0-2.62.142-3.38.55-.641.342-1.138.839-1.48 1.48-.408.76-.55 1.499-.55 3.38v7.18c0 1.881.142 2.62.55 3.38.342.641.839 1.138 1.48 1.48.76.407 1.499.55 3.38.55h7.18c1.881 0 2.62-.142 3.38-.55a3.544 3.544 0 001.48-1.48c.407-.76.55-1.499.55-3.38v-7.18c0-1.881-.142-2.62-.55-3.38a3.544 3.544 0 00-1.48-1.48c-.76-.408-1.499-.55-3.38-.55z" /><path d="M14.759 18.33c-4.513 0-7.25-3.127-7.358-8.33h2.286c.071 3.822 1.81 5.437 3.143 5.77V10h2.19v3.3c1.286-.143 2.631-1.645 3.083-3.3h2.156c-.345 2.036-1.81 3.531-2.845 4.15 1.036.5 2.703 1.811 3.346 4.18h-2.37c-.5-1.583-1.726-2.804-3.37-2.97v2.97z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoVkOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoVkOutline: FC<Icon28LogoVkOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LogoVkOutline as any).mountIcon = mountIcon;

export default Icon28LogoVkOutline;
